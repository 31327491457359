import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AppService} from 'src/app/app.service';
import {BuyEcashModalPage} from '../buy-ecash-modal/buy-ecash-modal.page';

@Component({
    selector: 'app-viewer-pay-modal',
    templateUrl: './viewer-pay-modal.page.html',
    styleUrls: ['./viewer-pay-modal.page.scss'],
})
export class ViewerPayModalPage implements OnInit {
    constructor(
        public app: AppService,
        private modalCtrl: ModalController,
    ) {
    }

    isReview = false;
    chapter;
    currentChapter;

    is_order = false;

    //////////////////////////////////////////////
    // 리뷰 관련

    // 리뷰 작성
    reviewForm: any = {
        rating: 5,
        content: '',
    };

    // 리뷰 리스트
    reviewData: any = {

        // added value
        sortNm: 'LIKE', // DATE, LIKE, COMMENT
        sort: 'DESC',

        // default
        total: 0,
        list: [],
        no: 1,
        size: 3,


        loading: true,
        more: true,
        refresher: null,
        infiniteScroll: null,
    };

    ngOnInit() {
        // alert(this.isReview);
        if (this.isReview && this.currentChapter) {
            this.initReviewList();

        }

        this.initChapterPrice();
    }

    close(data?) {
        this.modalCtrl.dismiss(data);
    }

    async initChapterPrice() {
        try {
            const chapter_res = await this.app.http.get(`api/v1/chapter/check/${this.chapter.chapter_id}`);
            this.chapter = chapter_res.data;
            this.is_order = this.chapter.is_order == 'Y' ? true : false;
        } catch (e) {
            this.app.ctrl.alertError(e);
        }
    }

    async buyecash() {
        this.app.ctrl.openModal(BuyEcashModalPage, {}, async (res) => {
        });
    }

    /*
      주문처리
      잔액이 있으면, 결제처리 후 닫으면서 NEXT 리턴
      잔액이 없으면, buyecash 나오게 하기
    */
    async purchase(order_type, ecash) {
        // 결제완료 처리 후,
        const loading = await this.app.ctrl.loading('MSG_TRANSACTION');
        try {
            // 보유잔액과 구매캐시 확인
            const myecash = this.app.user.current.ecash;
            // alert("보유 cash: " + myecash)
            // alert("결제금액 : " + ecash);

            if (+myecash >= +ecash) {
                // 구매가능
            } else {
                // You have only {{myecash}} E-Cash.<br>Would you like to buy E-Cash?
                // E-Cash is insufficient. Would you like to purchase more?
                this.app.ctrl.alertConfirm(this.app.getTranslate('NOT_ENOUGH_ECASH_BUY', {cash: myecash}), () => {
                    this.buyecash();
                });
                return;
            }

            ///////////////////////////// 주문완료처리 /////////////////////////
            const param = {
                chapters: [this.chapter.chapter_id]
            };
            await this.app.http.post(`api/v1/order/chapters/${this.chapter.title_id}/${order_type}`, param);
            ///////////////////////////// 주문완료처리 @/////////////////////////

            this.close({action: 'PAID'});

        } catch (e) {
            this.app.ctrl.alertError(e);
        } finally {
            loading.dismiss();
        }
    }


    continueChapter() {
        this.close({action: 'PAID'});
    }

    setRating(n) {
        this.reviewForm.rating = n;
    }

    initReviewList() {
        this.reviewData.loading = true;
        this.reviewData.list = [];
        this.reviewData.no = 1;
        this.reviewData.more = true;
        // this.loadReviewList();//TODO
    }

    refreshReviewList($event) {
        this.reviewData.refresher = $event;
        this.initReviewList();
    }

    moreReviewList($event) {
        this.reviewData.infiniteScroll = $event;
        this.reviewData.no++;
        // this.loadReviewList();//TODO

    }

    // async loadReviewList(){


    //   try{

    //     let param = {
    //       sortNm: this.reviewData.sortNm,
    //       sort: this.reviewData.sort,
    //       no: this.reviewData.no,
    //       size: this.reviewData.size,
    //     }
    //     let res  =  await this.api.get('/v2/content/title/review/'+ this.currentChapter.title_id, param).toPromise();
    //     let result = res.data;

    //     // loop
    //     for(let item of result ){
    //       let _item = item;
    //       _item.isExpand = false;
    //       _item.isLike = item.is_like == 'Y' ? true:false;
    //       // _item.ymd = moment( item.regdt).format('YYYY년 M월 D일');
    //       this.reviewData.list.push(_item);
    //     }
    //     // loop@

    //     // complete
    //     this.app.page.loadComplete( this.reviewData, result );

    //   }catch(e){
    //       alert(e.message);
    //   }finally{

    //   }

    // }

    // 정렬순서 변경
    setReviewSort(sortNm) {
        this.reviewData.sortNm = sortNm;
        this.initReviewList();
    }


    // // 리뷰 신규, 수정
    // async addReview(){
    //   try{
    //     let param = {
    //       chptrId: this.chapter.id,
    //       starCnt: this.reviewForm.rating,
    //       rvwsCntn: this.reviewForm.content,
    //     }
    //     alert(JSON.stringify(param));

    //     let result = await this.api.post('/v2/content/title/review/' + this.chapter.title_id, param ).toPromise();

    //     this.reviewForm.content = '';

    //     this.initReviewList();
    //   }catch(e){
    //     alert(e.message);
    //   }
    // }

}
