import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { KeyStore } from '../type/enum';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate{
  constructor(  
    private router: Router, 
    private app: AppService
    ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    // const redirect = state.url.replace('/', '');
    // console.log('window.location  = ' + window.location);
    // console.log('state.url  = ' + state.url);
    // console.log('next.url  = ' + next.url);
    // console.log('redirect = ' + redirect);
    let url: string = state.url;
    return this.isAuthenticated(next, url);
  } 

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot):  boolean {
    // const redirect = state.url.replace('/', '');
    // console.log('window.location  = ' + window.location);
    // console.log('state.url  = ' + state.url);
    // console.log('next.url  = ' + next.url);
    let url: string = state.url;
    return this.isAuthenticated(next, url);
  }

  // 로그인되었을때만 이동 
  isAuthenticated(route: ActivatedRouteSnapshot, url: any): boolean {

    let is_login = this.app.user.isLogin();

    if( is_login ){
        return true
      } else {
        localStorage.setItem( KeyStore.redirect, url);
        this.app.goReplace('login');
        return false
      }  
  }


}
