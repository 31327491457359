// https://momentjs.com/timezone/

type Category = {
    text 
    value
}

/*
    category: array
    enum: object 
*/
export const SiteCategory:Array<any> = [
    { value:'EN', lang:'en', country:'en', flag:'us', text:'English', tz:'America/New_York'}, 
    { value:'KO', lang:'ko', country:'ko', flag:'kr', text:'한국어', tz:'Asia/Seoul'},
    { value:'JA', lang:'ja', country:'ja', flag:'jp', text:'日本語', tz:'Asia/Tokyo'},
    { value:'CN', lang:'cn', country:'cn', flag:'cn', text:'中文', tz:'Asia/Shanghai'},

    { value:'ES', lang:'es', country:'es', flag:'es', text:'Español', tz:'Europe/Madrid'},
    { value:'FR', lang:'fr', country:'fr', flag:'fr', text:'Français', tz:'Europe/Paris'},
    { value:'DE', lang:'de', country:'de', flag:'de', text:'Deutsch', tz:'Europe/Berlin'},
    
    { value:'ID', lang:'id', country:'id', flag:'id', text:'Bahasa Indonesia', tz:'Asia/Jakarta'},
    { value:'VI', lang:'vi', country:'vi', flag:'vi', text:'Tiếng Việt', tz:'Asia/Ho_Chi_Minh'},
    { value:'TH', lang:'th', country:'th', flag:'th', text:'ไทย', tz:'Asia/Bangkok'},
    
]
 

export const bannerCategory:Array<Category>=[
    {value:'main',text:'MAIN'},
    {value:'top',text:'TOP'},
    {value:'bottom',text:'BOTTOM'},
    {value:'new',text:'NEW'},
    {value:'romance',text:'GENRE(Romance)'},
    {value:'s-romance',text:'GENRE(S-Romance)'},
    {value:'bl',text:'GENRE(BL)'},
    {value:'drama',text:'GENRE(Drama)'},
    {value:'fantasy',text:'GENRE(Fantasy)'},
    {value:'comedy',text:'GENRE(Comedy)'},
    {value:'sci-fi',text:'GENRE(Sci-fi)'},
    {value:'thriller',text:'GENRE(Thriller)'},
    {value:'action',text:'GENRE(Action)'},
]