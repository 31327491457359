import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            console.log('클라이언트 에러', error);
            errorMsg = `Error: ${error.error.message}`;
          }
          else {
            console.log('서버사이드 에러', error);
            if( error.error ){
              errorMsg = `Error Code: ${error.error.statusCode || error.status},  Message: ${error.error.message || error.message}`;
            }else{
              errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            }
          }
 
          if( error.error?.statusCode != 401 ){
            console.log("인터셉터 : " + errorMsg);
          }
          console.log(error.status)
          if ( error.status == 0) {
            return;
          }

          return throwError(error.error || error );
        })
      )
  }
}