import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/shared/guards/auth.guard';
import { LanguageGuard } from 'src/shared/guards/lanuage.guard';
import { UnAuthGuard } from 'src/shared/guards/unauth.guard';

const routes: Routes = [

  // 언어셋
  {
    path: 'jp',
    canActivate: [ LanguageGuard ],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'JP',
    canActivate: [ LanguageGuard ],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  // 테스트링크
  {
    path: 'test/:lang',
    canActivate: [ LanguageGuard ],
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },

  {
    path: ':site?/home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'new',
    loadChildren: () => import('./new/new.module').then( m => m.NewPageModule)
  },
  {
    path: 'free',
    loadChildren: () => import('./free/free.module').then( m => m.FreePageModule)
  },
  {
    path: 'genre',
    loadChildren: () => import('./genre/genre.module').then( m => m.GenrePageModule)
  },
  {
    path: 'event',
    loadChildren: () => import('./event/event.module').then( m => m.EventPageModule)
  },
  {
    path: 'review',
    loadChildren: () => import('./review/review.module').then( m => m.ReviewPageModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then( m => m.TermsPageModule)
  },
  {
    path: 'comic/:title_key',
    loadChildren: () => import('./comic/comic.module').then( m => m.ComicPageModule)
  },
  {
    path: ':title_site/comic/:title_key',
    loadChildren: () => import('./comic/comic.module').then( m => m.ComicPageModule)
  },
  {
    path: 'event/:id',
    loadChildren: () => import('./event-view/event-view.module').then( m => m.EventViewPageModule)
  },
  {
    path: 'policy',
    loadChildren: () => import('./policy/policy.module').then( m => m.PolicyPageModule)
  },

  {
    path: 'author/:author_id',
    loadChildren: () => import('./author/author.module').then( m => m.AuthorPageModule)
  },
  {
    path: 'provider/:provider_id',
    loadChildren: () => import('./provider/provider.module').then( m => m.ProviderPageModule)
  },
  {
    path: 'changepw',
    canActivate: [UnAuthGuard],
    loadChildren: () => import('./changepw/changepw.module').then( m => m.ChangepwPageModule)
  },
  {
    path: 'login',
    canActivate: [UnAuthGuard],
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'regist',
    canActivate: [UnAuthGuard],
    loadChildren: () => import('./regist/regist.module').then( m => m.RegistPageModule)
  },
  {
    path: 'findpw',
    canActivate: [UnAuthGuard],
    loadChildren: () => import('./findpw/findpw.module').then( m => m.FindpwPageModule)
  },
  {
    path: 'confirm-email',
    loadChildren: () => import('./confirm-email/confirm-email.module').then( m => m.ConfirmEmailPageModule)
  },
  {
    path: 'search-result',
    loadChildren: () => import('./search-result/search-result.module').then( m => m.SearchResultPageModule)
  },


  {
    path: 'viewer/:title_id/:chapter_id',
    loadChildren: () => import('./viewer/viewer.module').then( m => m.ViewerPageModule)
  },


  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then( m => m.SupportPageModule)
  },
  {
    path: 'myreview',
    canActivate: [AuthGuard],
    loadChildren: () => import('./myreview/myreview.module').then( m => m.MyreviewPageModule)
  },
  {
    path: 'mylibrary',
    canActivate: [AuthGuard],
    loadChildren: () => import('./mylibrary/mylibrary.module').then( m => m.MylibraryPageModule)
  },
  {
    path: 'mycoupon',
    canActivate: [AuthGuard],
    loadChildren: () => import('./mycoupon/mycoupon.module').then( m => m.MycouponPageModule)
  },
  {
    path: 'myecash',
    canActivate: [AuthGuard],
    loadChildren: () => import('./myecash/myecash.module').then( m => m.MyecashPageModule)
  },
  {
    path: 'push',
    canActivate: [AuthGuard],
    loadChildren: () => import('./push/push.module').then( m => m.PushPageModule)
  },
  {
    path: 'myaccount',
    canActivate: [AuthGuard],
    loadChildren: () => import('./myaccount/myaccount.module').then( m => m.MyaccountPageModule)
  },
  {
    path: 'notice',
    loadChildren: () => import('./notice/notice.module').then( m => m.NoticePageModule)
  },
  {
    path: 'adult-modal',
    loadChildren: () => import('./modals/adult-modal/adult-modal.module').then( m => m.AdultModalPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'buy-ecash-modal',
    loadChildren: () => import('./modals/buy-ecash-modal/buy-ecash-modal.module').then( m => m.BuyEcashModalPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'viewer-pay-modal',
    loadChildren: () => import('./modals/viewer-pay-modal/viewer-pay-modal.module').then( m => m.ViewerPayModalPageModule)
  },
  {
    canActivate: [AuthGuard],
    path: 'stripe-modal',
    loadChildren: () => import('./modals/stripe-modal/stripe-modal.module').then( m => m.StripeModalPageModule)
  },
  {
    path: 'developer/me',
    loadChildren: () => import('./developer/developer.module').then( m => m.DeveloperPageModule)
  },
  {
    path: 'setting',
    loadChildren: () => import('./setting/setting.module').then( m => m.SettingPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then( m => m.ErrorPageModule)
  },
  {
    path: 'more',
    canActivate: [AuthGuard],
    loadChildren: () => import('./more/more.module').then( m => m.MorePageModule)
  },
  {
    path: 'novel',
    loadChildren: () => import('./novel/novel.module').then( m => m.NovelPageModule)
  },
  {
    path: 'setting-email',
    loadChildren: () => import('./setting-email/setting-email.module').then( m => m.SettingEmailModule)
  },
  {
    path: 'setting-password',
    loadChildren: () => import('./setting-password/setting-password.module').then( m => m.SettingPasswordModule)
  },
  {
    path: 'setting-profile',
    loadChildren: () => import('./setting-profile/setting-profile.module').then( m => m.SettingProfileModule)
  },
  {
    path: 'setting-unregister',
    loadChildren: () => import('./setting-unregister/setting-unregister.module').then( m => m.SettingUnregisterModule)
  },
  {
    path: 'inbox-mycoupons',
    loadChildren: () => import('./inbox-mycoupons/inbox-mycoupons.module').then( m => m.InboxMycouponsModule)
  },
  {
    path: 'freecoind',
    loadChildren: () => import('./free-coind/free-coind.module').then( m => m.FreeCoindPageModule)
  },
  // 그래도 못찾으면 여기로
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
   
  },
  {
    path: '**',
    redirectTo: 'home',
  },
  {
    path: 'review-modal',
    loadChildren: () => import('./modals/review-modal/review-modal.module').then( m => m.ReviewModalPageModule)
  },
 

];

@NgModule({
  imports: [ 
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
    // onSameUrlNavigation: 'reload'
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
