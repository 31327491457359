import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
  } from '@angular/common/http';
  import { Observable, throwError } from 'rxjs';
  import { map, catchError } from 'rxjs/operators';
  import {
    ActivationEnd,
    Router
  } from '@angular/router';
  import { Platform, ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core'; 
import { KeyStore } from '../type/enum';
import { AppService } from 'src/app/app.service';

  
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    
    constructor(
      private platform: Platform,
      private router: Router,
      public toastController: ToastController,
    ) {
     
    }
     
      intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      /*
        site: 사이트 
        adult : Y:성인, N:성인아님 
        token : 로그인 token  
      */
      const did =  this.getDid();
      const platform = this.getPlatform();
      const token = localStorage.getItem( KeyStore.token );
      const site =  localStorage.getItem( KeyStore.site ) || 'EN';
      const adult = localStorage.getItem( KeyStore.adult ) == 'Y' ? 'Y' : 'N';  

      // set headers       
      request = request.clone({
        headers: request.headers.set('did', did)
      });
      request = request.clone({
        headers: request.headers.set('platform', platform)
      });
      request = request.clone({
        headers: request.headers.set('site', site)
      });
      request = request.clone({
        headers: request.headers.set('adult', adult)
      }); 
      
      if( token){
        //로그인시 
        request = request.clone({
          headers: request.headers.set('token', token)
        });      
      } 
   
      return next.handle(request).pipe(        
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // console.log('event--->>>', event);
          }
          return event;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            if (error.error.success === false) {
              alert('Login failed');
            } else {
              this.router.navigate(['login']);
            }
          }
          return throwError(error);
        }));
    }

    getPlatform(){
      let platform = 'web';
      if( this.platform.is('cordova') || this.platform.is('capacitor')){
        if( this.platform.is('ios') ){
          platform = 'ios'
        }else if( this.platform.is('android')){
          platform = 'android'
        }
      }
      // console.log("플랫폼", platform)
      return platform;
    }
 
    getDid(){
      let did = localStorage.getItem( KeyStore.did );
      if( did ){
      }else{
        did = new Date().getTime()+'';
        localStorage.setItem( KeyStore.did , did);
      }
      return did;
    }
}