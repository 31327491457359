import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { SiteCategory } from '../type/category';



@Injectable({
  providedIn: 'root'
})
export class LanguageGuard implements CanActivate{
  constructor(  
    private router: Router, 
    private app: AppService
    ) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean>{
        // console.log('state.url  = ' + state.url);
        // console.log('next.url  = ' + next.url);
        

        try{
          let country = ((next.url || '') + '');
          country = country.replace('test,','');
          if(country.toLocaleLowerCase() == 'jp'){
            country = 'ja'; //JP, jp -> ja로 변경 
          }
          const lang = this.app.service.convertCountryToLanuage( country );
          return this.app.service.changeLanguage( lang, ()=>{})        
        }catch(e){
          return true;      
        }
  } 
}
