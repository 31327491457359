import {DOCUMENT} from '@angular/common';
import {Component, Inject, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import { AppService } from 'src/app/app.service';





// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({
    selector: 'app-select-language-modal',
    templateUrl: './select-language-modal.page.html',
    styleUrls: ['./select-language-modal.page.scss'],
})
export class SelectLanguageModalPage implements OnInit {

    constructor(
        public app: AppService,
        private modalCtrl: ModalController
    ) {
    }

    close(data?) {
        this.modalCtrl.dismiss(data);
    }

    async ngOnInit() {
        console.log(this.app.cache);
    }

}
