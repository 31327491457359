import {DOCUMENT} from '@angular/common';
import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {fromEvent, Observable} from 'rxjs';
import {first} from 'rxjs/operators';

import {AppService} from 'src/app/app.service';
import {config} from 'src/config/config';
import { StoreProductList } from 'src/shared/entities/interface.mo';
import {PayMethod} from 'src/shared/type/enum';
import {StripeModalPage} from '../stripe-modal/stripe-modal.page';

declare var paypal;

// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({
    selector: 'app-buy-ecash-modal',
    templateUrl: './buy-ecash-modal.page.html',
    styleUrls: ['./buy-ecash-modal.page.scss'],
})
export class BuyEcashModalPage implements OnInit {
    chargingModal;
    constructor(
        @Inject(DOCUMENT)
        private document: Document,
        public app: AppService,
        private modalCtrl: ModalController
    ) {
    }
    PayMethod = PayMethod;

    payData = {
        order_id: '', // 주문번호
        pay_method: '', // 결제방법
        pg_auth_no: '', // 결제승인번호
        pg_username: '', // 결제자명
        signature: '', // 결제 signature,
        amount: 0, // 결제금액
        ecash: 0,
        point: 0,
        product_price: 0,
        product_id: '', // store 주문 번호
        email: ''
    };
    productList: StoreProductList[] = [];
    productFirstList: StoreProductList[] = [];
    productMonthList: StoreProductList[] = [];


    // paypal
    @ViewChild('paypalRef', {static: true}) private paypalRef: ElementRef;

    close(data?) {
        this.modalCtrl.dismiss(data);
    }

    async ngOnInit() {
        this.payData.email = this.app.user.getUser().email;
        this.payData.pg_username = this.app.user.getUser().email;
        // price list setting
        // this.selectPrice(3); // 29.99 디폴트 선택
        await this.getProductSetting();
 
        this.payData.pay_method = PayMethod.paypal;
        // paypal setting
        await this.initiate().subscribe(
            () => {
                this.initPaypal();
            });
    }

    checkCountryLanguage(site, item) {
        if (site == 'KO' && item.ko_title) {
            return {
                checkVal: true,
                title: item.ko_title,
                des: item.ko_des
            };
        } else if (site == 'EN' && item.en_title) {
            return {
                checkVal: true,
                title: item.en_title,
                des: item.en_des
            };
        } else if (site == 'CN' && item.cn_title) {
            return {
                checkVal: true,
                title: item.cn_title,
                des: item.cn_des
            };
        } else if (site == 'JA' && item.ja_title) {
            return {
                checkVal: true,
                title: item.ja_title,
                des: item.ja_des
            };
        } else if (site == 'VI' && item.vi_title) {
            return {
                checkVal: true,
                title: item.vi_title,
                des: item.vi_des
            };
        } else if (site == 'TH' && item.th_title) {
            return {
                checkVal: true,
                title: item.th_title,
                des: item.th_des
            };
        } else if (site == 'ID' && item.id_title) {
            return {
                checkVal: true,
                title: item.id_title,
                des: item.id_des
            };
        } else if (site == 'ES' && item.es_title) {
            return {
                checkVal: true,
                title: item.es_title,
                des: item.es_des
            };
        } else if (site == 'FR' && item.fr_title) {
            return {
                checkVal: true,
                title: item.fr_title,
                des: item.fr_des
            };
        } else if (site == 'DE' && item.de_title) {
            return {
                checkVal: true,
                title: item.de_title,
                des: item.de_des
            };
        }

        return {
            checkVal: false
        };
    }

    async getProductSetting() {
        // 현재 사이트의 국가 정보
        const setCountry = localStorage.getItem('ncx.user.site');
        const res = await this.app.http.get(`api/v1/product`);
        const list = res.data;       
        if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].product_type === '0') {
                    this.productList.push(
                        { 
                            product_id: list[i].product_id,
                            product_price: list[i].product_price,
                            ecash: list[i].ecash,
                            point: list[i].point,
                            en_title: list[i].en_title,
                            en_des: list[i].en_des,
                            product_type: list[i].product_type,
                            display_price: `$ ${list[i].product_price.toString()}`,
                            isSelect: false
                        }
                    )
                } else if (list[i].product_type === '2') {
                    const plankValue = this.checkCountryLanguage(setCountry, list[i]);
                    if (plankValue.checkVal) {
                        this.productFirstList.push(
                            { 
                                product_id: list[i].product_id,
                                product_price: list[i].product_price,
                                ecash: list[i].ecash,
                                point: list[i].point,
                                en_title: plankValue.title,
                                en_des: plankValue.des,
                                product_type: list[i].product_type,
                                display_price: `$ ${list[i].product_price.toString()}`,
                                isSelect: false
                            }
                        )
                    }
                
                } else {
                    const plankValue = this.checkCountryLanguage(setCountry, list[i]);
                    if (plankValue.checkVal) {
                        this.productMonthList.push(
                            { 
                                product_id: list[i].product_id,
                                product_price: list[i].product_price,
                                ecash: list[i].ecash,
                                point: list[i].point,
                                en_title: plankValue.title,
                                en_des: plankValue.des,
                                product_type: list[i].product_type,
                                display_price: `$ ${list[i].product_price.toString()}`,
                                isSelect: false
                            }
                        )
                    }
                   
                }
            }
        } else {
            // 데이터 없음
        }
       
    }

    initiate(): Observable<void> {
        try {
            const rpaypalScriptElement = this.document.getElementById('paypal-script');
            this.document.head.removeChild(rpaypalScriptElement);
        } catch (e) {
        }

        const paypalScriptElement: HTMLScriptElement = this.document.createElement('script');

        paypalScriptElement.src = `https://www.paypal.com/sdk/js?client-id=${config.paypal_client_id}`;
        paypalScriptElement.id = 'paypal-script';

        this.document.head.appendChild(paypalScriptElement);

        return fromEvent<void>(paypalScriptElement, 'load').pipe(first());
    }

    public remove(): void {
        const paypalScriptElement = this.document.getElementById('paypal-script');
        this.document.head.removeChild(paypalScriptElement);
    }


    makeCurrenyForm() {
        const setCountry = localStorage.getItem('ncx.user.site');
        let countryCode = 'USD';
        let currencySymbol = '$';

        switch (setCountry) {
            case 'EN':
                countryCode = 'USD';
                currencySymbol = '$';
                break;
            case 'KO':
                countryCode = 'KRW';
                currencySymbol = '₩';
                break;
            case 'JA':
                countryCode = 'JPY';
                currencySymbol = '¥';
                break;
            case 'CN':
                countryCode = 'CNY';
                currencySymbol = '¥';
                break;
            case 'ID':
                countryCode = 'IDR';
                currencySymbol = 'Rp';
                break;
            case 'VI':
                countryCode = 'VND';
                currencySymbol = '₫';
                break;
            case 'TH':
                countryCode = 'THB';
                currencySymbol = '฿';
                break;
            default:
                break;
        }


        return {
            countryCode,
            currencySymbol
        };
    }

    /////////////////////////
    // 금액,포인트 세팅
    async selectPrice(idx, type) {

        for (const item of this.productFirstList) {
            item.isSelect = false;
        }

        for (const item of this.productMonthList) {
            item.isSelect = false;
        }
        
        for (const item of this.productList) {
            item.isSelect = false;
        }

        if (type === '0') {
            this.productList[idx].isSelect = true;
            this.payData.product_price = this.productList[idx].product_price
            this.payData.ecash = this.productList[idx].ecash;
            this.payData.point = this.productList[idx].point;
            this.payData.product_id = this.productList[idx].product_id;
        } else if (type === '2') {
            // 이미 구매한 적이 있는지 체크 후 동작 필요!
            this.productFirstList[idx].isSelect = true;
            this.payData.product_price = this.productFirstList[idx].product_price;
            this.payData.ecash = this.productFirstList[idx].ecash;
            this.payData.point = this.productFirstList[idx].point;
            this.payData.product_id = this.productFirstList[idx].product_id;

            const param = {
                email: this.payData.email,
                product_id: this.payData.product_id 
            };
            const res = await this.app.http.post(`api/v1/payment/check/first`, param);
            if (res.data) {
                this.app.ctrl.alert('CHECK_PRODUCT_PUR')
                this.selectPrice(2, '0')
                return;
            }
           
        } else {
            this.productMonthList[idx].isSelect = true;
            this.payData.product_price = this.productMonthList[idx].product_price;
            this.payData.ecash = this.productMonthList[idx].ecash;
            this.payData.point = this.productMonthList[idx].point;
            this.payData.product_id = this.productMonthList[idx].product_id;
            const param = {
                email: this.payData.email,
                product_id: this.payData.product_id 
            };
            const res = await this.app.http.post(`api/v1/payment/check/monthly`, param);
            if (res.data) {
                this.app.ctrl.alert('CHECK_MPRODUCT_PUR')
                this.selectPrice(2, '0')
                return;
            }
          
        }
     
    }

    selectPayment(payType) {
        // console.log('selectPayment: ' + payType);
        this.payData.pay_method = payType;
    }

    async initPaypal() {
        // let loading;
        paypal.Buttons({
                clientId: 'AfU65diRJvoG412gFDX_7_5xIUNEjZfvZyitfgeQvaNF5lG432IrsnBW8nBZn9vHs8YGCDT3LBSVC40k', // real
                style: {
                    layout: 'horizontal',
                    color: 'blue',
                    shape: 'rect',
                },
                createOrder: async (data, actions) => {
                    console.log('createOrder-----');
                    await this.createPayment(PayMethod.paypal);

                    // loading = await this.app.ctrl.loading('PAY_APPROVE');

                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                currency_code: 'USD',
                                value: this.payData.product_price
                            },
                            description: 'NETCOMICS - ' + this.payData.ecash + ' E-Cash (' + this.payData.order_id + ')',
                            custom_id: this.app.user.current.username
                        }],
                    });
                },

                onApprove: async (data, actions) => {
                    console.log('onApprove - transaction was approved, but not authorized', data, actions);

                    try {
                        const details = await actions.order.capture();
                        console.log('onApprove - you can get full order details inside onApprove: ', details);
                        this.payData.pg_auth_no = details.id; // 결제승인번호
                        this.finishChargePayment(details);

                    } catch (e) {
                        alert(e.message);
                    } finally {
                        // loading.dismiss();
                    }
                },
                onClientAuthorization: (data) => {
                    console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
                    // this.showSuccess = true;
                },
                onCancel: async (data, actions) => {
                    console.log('OnCancel', data, actions);
                    this.cancelPayment();
                    // loading.dismiss();
                },
                onError: err => {
                    alert('error');
                    console.log('OnError', err);
                    // loading.dismiss();
                },
                onClick: async (data, actions) => {
                }
            },
        ).render(this.paypalRef.nativeElement);

    }

    // stripe
    async doStripe() {

        // 결제준비
        await this.createPayment(PayMethod.stripe);

        const modal = await this.app.ctrl.openInlineModal(StripeModalPage, {ecash: this.payData.product_price}, 'modal-inline-stripe');

        modal.onDidDismiss().then(async (res) => {
            if (res.data) {
                if (res.data && res.data.status === 'succeeded') {
                    // 결제완료처리
                    const loading = await this.app.ctrl.loading('CHARGING');
                    try {

                        // 주문처리 데이터
                        this.payData.pg_auth_no = res.data.id; // 결제승인번호
                        this.finishChargePayment(res.data);

                    } catch (e) {
                        // this.app.ctrl.alertError(e);
                        console.log(e);
                    } finally {
                        loading.dismiss();
                    }

                } else {
                    alert('stripe error');
                }
            } else {
                // 리턴값이 없으면 캔슬처리
                this.cancelPayment();
            }

        });
    }

    // test
    async doDirect() {
        // this.chargingModal = await this.app.ctrl.alertPurchase();
        // 결제키 준비
        await this.createPayment(PayMethod.test);

        // // 테스트 결제승인
        this.payData.pg_auth_no = new Date().getTime() + '';
        this.payData.pg_username = this.app.user.current.email;

        // // 주문처리 데이터
        this.finishChargePayment({test: true});
       
    }


    productApproved = (p) => {
        // console.log('[productApproved]');
        // console.log(p);
        p.verify();
    }

    productVerified = (p) => {
        // console.log('product_id: ' + this.payData.product_id  + ', order_id: ' + this.payData.order_id);
        this.chargingModal.dismiss();
        // mobile 에서는 transaction id를 pg_auth_no 로 사용한다
        if (this.payData.product_id == p.id) {
            this.finishChargePayment(p);
        }
    }

    productUnverified = (p) => {
        this.chargingModal.dismiss();
    }

    productOwned = (p) => {
    }

    productError = (p) => {
        this.chargingModal.dismiss();
    }

   

    ////////////////////////
    // API

    // 결제생성
    async createPayment(payMethod) {
        const param = {
            pay_method: payMethod, // CREDITCARD, PAYPAL, IOS, ANDROID, STRIPE
            ecash: this.payData.ecash,
            point: this.payData.point,
            email: this.payData.email,
            product_id: this.payData.product_id,
            product_price: this.payData.product_price
        };

        const res = await this.app.http.post(`api/v1/payment/create`, param);
        this.payData.order_id = res.data;
    }

    async cancelPayment() {
        if (this.payData.order_id) {
            await this.app.http.post(`api/v1/payment/cancel`, {order_id: this.payData.order_id});
        }
    }

    // 결제완료, 충전하기
    async finishChargePayment(pgResult) {
        const loading = await this.app.ctrl.loading('CHARGING');
        try {
            const param = {
                ecash: this.payData.ecash,
                point: this.payData.point,
                order_id: this.payData.order_id,
                // 결제데이터
                pg_auth_no: this.payData.pg_auth_no,  // new Date().getTime()
                pg_username: this.payData.pg_username, // this.app.user.current.email,
                pg_result_json: pgResult,
            };
            console.log('최종 payments param : ', param);

            const res = await this.app.http.post(`api/v1/payment/complete`, param);
         
            if ( pgResult.finish ) {
                pgResult.finish();
            }
            // this.payData.order_id = '';
            // 유저캐시갱신
            this.app.user.refreshEcash();

            this.close('ok');
        } catch (e) {
            try {
                // console.log('check payments param : ', pgResult.transaction.id);
                const res = await this.app.http.get(`api/v1/payment/find/pg/${pgResult.transaction.id}`);
                // console.log('check payments result: ', res.data);
                if ( res.data && res.data.pay_status === 'success' ) {
                    pgResult.finish();
                }
            } catch (e) {
                alert(e.message);
            }
        } finally {
            loading.dismiss();
        }
    }
}


