import {
  Injectable,
  NgZone,
} from "@angular/core";

// 3rd party library
import * as moment from "moment";
// import toDate from 'date-fns/toDate'
import * as sha256 from "sha256";
// import * as $ from "jquery";
import * as querystring from "querystring";

import { TranslateService } from "@ngx-translate/core";
import {
  AlertController,
  LoadingController,
  ToastController,
  ModalController,
  Platform,
  NavController
} from "@ionic/angular";
import { Events } from "./services/events";
import {
  ActivatedRoute,
  Router,
  NavigationEnd,
} from "@angular/router";
import { filter } from "rxjs/operators";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from "angularx-social-login";
import { config } from "src/config/config";
import { HttpClient } from "@angular/common/http";
import { KeyStore } from "src/shared/type/enum";
import { SocialLogin } from "src/shared/entities/social-login";

import detectBrowserLanguage from "detect-browser-language";
import { SiteCategory } from "src/shared/type/category";
import { differenceInCalendarDays } from "date-fns";
import { environment } from "src/environments/environment";
declare var dataLayer;
@Injectable()
export class AppService {
  constructor(
    public translate: TranslateService,
    private events: Events,
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private platform: Platform,
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private httpClient: HttpClient,
    private ngZone: NgZone,
    private socialAuthService: SocialAuthService
  ) {
    this.currentTab = "home";
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });

    if (platform.is("ios")) {
      this.env.isIOS = true;
      this.env.platform = "ios";
    } else {
      this.env.isIOS = false;
      this.env.platform = "android";
    }
    
    localStorage.setItem("user-platform", this.env.platform);
  }

  /*
      this.app.config.isIOS;
    */
  env = {
    isAdultService: true, // 19세는 앱에서는 막기
    isIOS: false, // android, ios
    isCordova: false, // app
    isMobileWeb: false, // mobile web
    isPC: true, // pc
    isDev: false,
    platform: "web", // WEB, IOS, ANDROID
  };

  skeleton = {
    list2: [1, 2],
    list4: [1, 2, 3, 4],
    list6: [1, 2, 3, 4, 5, 6],
    list8: [1, 2, 3, 4, 5, 6, 7, 8],
    list10: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    list12: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    list15: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    list18: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
  };
  currentUrl = "";
  previousUrl = "";
  currentTab = "home";
  // generalPage = [];

  // HTTP
  public http = {
    post: async (uri, param?) => {
      param = param || {};
      const result: any = await this.httpClient
        .post(config.api_endpoint + uri, param)
        .toPromise();
      return result;
    },
    put: async (uri, param?) => {
      param = param || {};
      const result: any = await this.httpClient
        .put(config.api_endpoint + uri, param)
        .toPromise();
      return result;
    },
    patch: async (uri, param?) => {
      param = param || {};
      const result: any = await this.httpClient
        .patch(config.api_endpoint + uri, param)
        .toPromise();
      return result;
    },
    get: async (uri, param?) => {
      const queryParam = param ? "?" + querystring.stringify(param) : "";
      const result: any = await this.httpClient
        .get(config.api_endpoint + uri + queryParam)
        .toPromise();
      return result;
    },
    delete: async (uri, param?) => {
      const queryParam = param ? "?" + querystring.stringify(param) : "";
      const result: any = await this.httpClient
        .delete(config.api_endpoint + uri + queryParam)
        .toPromise();
      return result;
    },
  };

  /**
   * 메인화면에서만 탭 나오도록 하기
   */
  public showTabBarPages: string[] = [
    "/tabs/find",
    "/tabs/club",
    "/tabs/shop",
    "/tabs/like",
    "/tabs/business",
    "/tabs/profile",
    "/tabs/admin",
  ];

  private modals: any[] = [];

  modalAdd(modal: any) {
    // add modal to array of active modals
    this.modals.push(modal);
  }

  modalRemove(id: string) {
      // remove modal from array of active modals
      this.modals = this.modals.filter(x => x.id !== id);
  }

  modalOpen(id: string) {
      // open modal specified by id
      const modal = this.modals.find(x => x.id === id);
      modal.open();
  }

  modalClose(id: string) {
      // close modal specified by id
      const modal = this.modals.find(x => x.id === id);
      modal.close();
  }

  public user = {
    current: {
      email: "",
      nickname: "",
    } as any,
    isLogin: () => {
      return localStorage.getItem(KeyStore.token) ? true : false;
    },
    isAdult: () => {
      return localStorage.getItem(KeyStore.adult) == "Y" ? true : false;
    },
    initLanguage: async () => {
      const site = this.user.getCurrentSite();
      this.translate.use(site);
    },
    getCurrentSite: () => {
      const lang = localStorage.getItem(KeyStore.site);
      // const lang = false;
      if (lang) {
        return lang;
      } else {
        // 자동으로 찾기
        try {
          let detect_lang = detectBrowserLanguage();
          console.log(detect_lang);
          if (detect_lang.indexOf("-") > -1) {
            detect_lang = detect_lang.split("-")[0];
          }
          if (detect_lang.toLowerCase() == "zh") {
            detect_lang = "CN";
          }
          console.log(`detect_lang: --- ${detect_lang}`);
          let final_lang = "EN";
          const find_lang = SiteCategory.find((val) => val.lang == detect_lang.toLowerCase() );
          if (find_lang) {
            final_lang = find_lang.value.toUpperCase();
          } else {
            final_lang = "EN";
          }
          // console.log(final_lang);
          console.log(`final_lang: --- ${final_lang}`);
          // 임시조치 : 한/영/중/일 + 독/프/스까지만 오픈 인도/베트/타이 나머지 9월중
          const not_available = ["ID", "VI", "TH"];
          if (not_available.includes(final_lang)) {
            console.log("현재 지원하기전인 언어입니다. EN으로 변경됩니다.");
            final_lang = "EN";
          }
          return final_lang;
        } catch (e) {
          return "EN";
        }
      }
    },
    changeCurrentSite: async (site) => {
      localStorage.setItem(KeyStore.site, site || "EN");
      console.log("user.site 변경 : " + site);

      this.service.initBanner(); // 베너 정보 갱신
      this.service.initSupportLanguage(); // 다국어정보 호출
      this.service.initGenre(); // 장르정보 갱신
      this.translate.use(site); // 번역적용

      // body태그 언어셋지정
      // $("body").removeClass();
      // $("body").addClass(site);

      // 캐시반영
      this.events.publish("change-site", {});
      return;
    },
    getUser: () => {
      const user = JSON.parse(localStorage.getItem(KeyStore.user));
      return user
    },
    getUserId: () => {
      let user_id = "";
      try {
        if (this.user.current.uid) {
          user_id = this.user.current.uid;
        } else {
          user_id = localStorage.getItem(KeyStore.did);
          // user_id = 'gtm_' + new Date().getTime();
        }
      } catch (e) {
        user_id = "error";
      }
      return user_id;
    },
    getAdultYn: () => {
      if (this.env.isCordova) {
        return "N"; // 앱 강제 성인 off
      } else {
        return localStorage.getItem(KeyStore.adult) == "Y" ? "Y" : "N";
      }
    },
    setAdultYn: (isAdult) => {
      if (this.env.isCordova) {
        localStorage.setItem(KeyStore.adult, "N"); // 앱 강제 성인 off
        return;
      } else {
        localStorage.setItem(KeyStore.adult, isAdult ? "Y" : "N");
        return;
      }
    },

    // 로그인, 로그아웃
    doLogin: async (username, password, cb?) => {
      const loading = await this.ctrl.loading("MSG_SIGN_IN");
      try {
        const param = {
          username,
          password,
        };
        // if (!param.username) {
        //     this.ctrl.alert(this.getTranslate('ENTER_EMAIL'));
        //     return;
        // }
        // if (!param.password) {
        //     this.ctrl.alert(this.getTranslate('ENTER_PW'));
        //     return;
        // }
        try {
          // 로그인처리
          const res: any = await this.http.post("auth/login", param);
          console.log(res);
          await this.user.setToken(res.data.token);
          await this.user.refreshBadgeCount();

          // 성공시 페이지 처리
          this.user.loginSuccessRedirect();

          // 로그인성공시 콜백
          if (cb) {
            cb();
          }
        } catch (e) {
          if (e.message === "NEED_EMAIL_VERIFIED") {
            this.ctrl.alertConfirm("NEED_EMAIL_VERIFIED", async () => {
              // 이메일 인증메일 발송하기
              try {
                
                await this.http.post(`auth/send/verify-email`, {
                  email: param.username,
                  site: this.user.getCurrentSite()
                });
                this.ctrl.alert("SENT_VERIFY_EMAIL"); // SENT_VERIFY_EMAIL
              } catch (e) {
                this.ctrl.alertError(e);
              }
            });
          } else if (
            e.message === "NOT_EXIST_ACCOUNT" ||
            e.message === "NOT_MATCH_ACCOUNT"
          ) {
            return e.message;
          } else {
            // 그외 에러메세지
            this.ctrl.alertError(e);
          }
        }
      } catch (e) {
        alert(JSON.stringify(e));
      } finally {
        loading.dismiss();
      }
    },

    loginSuccessRedirect: () => {
      // 로그인성공시 콜백
      const redirect = localStorage.getItem(KeyStore.redirect);
      if (redirect) {
        this.go(redirect);
      } else {
        this.goHome();
      }
      localStorage.removeItem(KeyStore.redirect);
    },

    doLogout: async () => {
      this.user.cleanUserData();

      try {
        const param = {
          token: this.user.getToken(),
        };
        await this.http.post("auth/logout", param);
      } catch (e) {
        alert("Session is invalidate." + e.message);
      }
      return;
    },
    getToken: () => {
      return localStorage.getItem(KeyStore.token);
    },
    setToken: async (token) => {
      localStorage.setItem(KeyStore.token, token);
      this.user.fetchUser();
      return;
    },
    setUser: (data) => {
      localStorage.setItem(KeyStore.user, JSON.stringify(data));
      // if(data.profile_img){
      //   data.profile_img = this.util.s3Url( data.profile_img);
      // }
      this.user.current = data;
    },
    refreshEcash: async () => {
      if (this.user.isLogin()) {
        const res = await this.http.post(`api/v1/ecash/refresh`);
        this.user.setUser(res.data);
      }
    },
    refreshBadgeCount: async () => {
      if (this.user.isLogin()) {
        const res = await this.http.get(`api/v1/push/count`);
        this.cache.badge.push = res.data;
      }
    },
    clearBadgeCount: async () => {
      this.cache.badge.push = 0;
    },
    // 현재 토큰으로 유저정보 갱신
    fetchUser: async () => {
      const token = localStorage.getItem(KeyStore.token);
      if (token) {
        // 유저정보 가져오기
        try {
          const res = await this.http.get("auth/token");
          this.user.setUser(res.data);

          // siteCd
          this.user.changeCurrentSite(this.user.current.site);

          // 메세지,쿠폰카운터
          this.user.refreshBadgeCount();

          // 유저캐시갱신
          this.user.refreshEcash();
        } catch (e) {
          console.log("token authentication error", e);
          if (e.status == 401) {
            this.user.cleanUserData();
          } else {
            this.ctrl.alertError(e);
          }
        }
      } else {
        // 로그아웃 상태일때, 현재 siteCd로 갱신
        this.user.changeCurrentSite(this.user.getCurrentSite());
        await this.user.cleanUserData();
      }

      // gtag
      dataLayer.push({
        uid: this.user.getUserId(),
        platform: this.env.platform,
      });

      return;
    },
    cleanUserData: async () => {
      // 로그아웃 처리
      localStorage.removeItem(KeyStore.user);
      localStorage.removeItem(KeyStore.adult);
      localStorage.removeItem(KeyStore.token);
      this.user.current = {
        username: "",
      };
      await this.user.clearBadgeCount();
      return;
    },
    needLogin: async (is_show_message?) => {
      if (!this.user.isLogin()) {
        if (is_show_message) {
          await this.ctrl.alertCallback(
            this.getTranslate("MSG_PLEASE_LOGIN"),
            () => {
              this.setRedirectUrl();
              this.go("login");
            }
          );
          return true;
        } else {
          this.setRedirectUrl();
          this.go("login");
          return true;
        }
      } else {
        return false;
      }
    },
  };
  public cache = {
    device: {
      is_network: true,
      is_service_status: true,
      service_message: "",
    },
    badge: {
      push: 0,
      coupon: 0,
    },
    banner: {
      top: [],
      main: [],
      bottom: [],
      event: [],
    },
    main_list: [] as any,
    main_user_list: [] as any,

    lang: {}, // 다국어 언어셋
    supportLanguageList: [], // 사용가능언어리스트

    isTopBannerOpen: false,

    // 내정보관리용
    myaccountDataCache: {} as any,

    // 푸시관련
    push: {
      new: false,
    },
    // 성인사용관련
    adult: {
      availableAdultContent: false, // 성인콘텐츠사용가능여부
      ageAdultAuthenticated: false, // 성인인증여부
      isShowAdultContent: false, // 성인컨텐츠보기
    },
    // 세팅 관련
    preference: [true, true, true, true, true, true],
    // 구매관련 캐시
    order: {
      items: [],
      priceTotal: 0,
    },
    cart: {
      items: [],
    },
    // 뷰어에서 보여줄 챕터정보 임시저장
    tempChapter: {},
    // 챕터관련 캐시
    current: {
      // 번들 타이틀 목록 및 구매여부
      bundle: {},
      bundleTitles: [],
      bundlePaidAccess: false,

      // 현재 챕터 + 이전화,다음화 목록
      chapters: [],
      currentChapter: {
        index: 0,
        title: "",
        images: [],
        previewPages: 0,
      },
      // 어디까지봤는지 정보
      lastChapter: {
        id: 0,
        index: 0,
      },
      // 기본정보 (작품 또는 번들)
      product: {
        id: 0,
        title: "",
        price_info: { rent: true, buy: true },
        suggested: [],
        authorRelated: [],
        type: "",
      },
    },

    // _modalPayPage, productId, chapterIndex, cid, previewPages
    setChapter: (
      _modalPayPage,
      productId,
      chapterIndex,
      cid,
      previewPages,
      isContinue
    ) => {
     

      return new Promise((resolve, reject) => {
        // cid로 array에서 해당 chapter 찾기
        for (let i = 0; i < this.cache.current.chapters.length; i++) {
          // chapter object
          const chapter = this.cache.current.chapters[i];

          // cid로 해당 챕터 찾기
          if (chapter.id == cid) {
            // 찾았다!  그럼 유/무료 체크먼저하고 (구매여부, 미리보기 확인)
            let buyPrice = 0;
            try {
              
              buyPrice = chapter.price.buy || 0;
              // alert(i + "->" + JSON.stringify( chapter['price']['buy'] ));
            } catch (e) {
              buyPrice = 0;
              console.error(
                "가격정보가 잘못되었습니다. 관리자에게 문의하세요."
              );
            }

            // 무료이거나, 유료 가격이 0인경우,
            if (chapter.free && chapter.mode == "rent") {
              // alert("rent 무료");
              console.log("무료");
            } else if (buyPrice == 0 && chapter.mode == "buy") {
              // alert("buy 무료");
            } else if (previewPages > 0) {
              console.log("미리보기");
            } else {
              console.log("유료");

              // 유료일 경우, 권한 체크
              if (chapter.paidAccess) {
                // 읽을 권한있음
                console.log("읽을 권한있음");
              } else {
                // 결제유도하기
                // this.service.payCheck(_modalPayPage, productId, chapterIndex, isContinue, false); //ModalPayPage, productId, chapterIndex, isContinue, isPreviewMode
                reject(chapter);
                break;
              }
            }

            // 읽을 권한이 있으면 세팅
            this.cache.current.currentChapter = chapter;
            this.cache.current.currentChapter.index = i;
            this.cache.current.currentChapter.previewPages = previewPages; // 미리보기 여부
            resolve(true);
            break;
          }
          // cid로 해당 챕터 찾기 @getMainData:
        }
      });
    },
  };

  ////////////////////////////////////////////////////////////////////////////////////

  public code = {
    genreList: [
      { code: "", text: "ALL" },
      { code: "romance", text: "ROMANCE" },
      { code: "bl", text: "BL" },
      { code: "drama", text: "DRAMA" },
      { code: "fantasy", text: "FANSTASY" },
      { code: "comedy", text: "COMEDY" },
      { code: "sci_fi", text: "SCI-FI" },
      { code: "thriller", text: "THRILLER" },
      { code: "action", text: "ACTION" },
    ],
  };

  ////////////////////////////////////////////////////////////////////////////////////
  public social = {
    cache: {
      provider: "facebook", // facebook, google
      redirect: "login", // connect
    },
    tempSocialLogin: {} as SocialLogin,

    /*
         Social utils
       */
    setSocial: (login_type, page) => {
      this.social.cache.provider = login_type;
      this.social.cache.redirect = page;
    },

    // social Login
    doSocialLogin: async (login_type, page) => {
      // 로그인후 처리방법 세팅!
      this.social.setSocial(login_type, page);
      console.log(login_type)
      if (login_type == "facebook") {
        const result = await this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
        const user: SocialLogin = {
          provider: "facebook",
          id: result.id,
          email: result.email, // || result.id + "@facebook.temp.com",
          nickname: result.firstName,
          first_name: result.firstName,
          last_name: result.lastName,
          profile_img: result.photoUrl,
        };
        this.social.socialLoginProcess(user)
      } else if (login_type == "google") {      
        const result = await this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
        const user: SocialLogin = {
          provider: "google",
          id: result.id,
          email: result.email, // || result.id + "@gmail.temp.com",
          nickname: result.firstName,
          first_name: result.firstName,
          last_name: result.lastName,
          profile_img: result.photoUrl,
          access_token: result.idToken,
        };
        this.social.socialLoginProcess(user)
        
      } else {
        alert("no support provider");
      }
    },

    /*
          1.
        */
    socialLoginProcess: async (loginData: SocialLogin) => {

      this.social.tempSocialLogin = loginData;
      if (!loginData.email) {
        // 이메일정보가 없습니다. 다른 소셜계정으로 연결하거나 이메일 로그인 후, 연결하여 주세요.
        this.ctrl.alert(this.getTranslate("MSG_SOCIAL_LOGIN_EMAIL_NOT_EXIST"));
        return;
      }

      const loading = await this.ctrl.loading("MSG_SIGN_IN");
      try {
        // 소셜연동여부 체크
        const checkResult: any = await this.http.post(
          `auth/social/check`,
          loginData
        );
        if (checkResult.data.exist_user === "Y") {
          // 소셜로그인 인증처리
          const res: any = await this.http.post(`auth/social/login`, loginData);
          console.log(res.data.token)
          await this.user.setToken(res.data.token);

          // 성공시 페이지 처리
          this.user.loginSuccessRedirect();
        } else {
          if (checkResult.data.exist_email === "Y") {
            // {{email}}로 가입한 계정이 있습니다. 이메일로 로그인하세요.
            this.ctrl.alert(
              this.getTranslate("MSG_SOCIAL_EXIST_USER_EMAIL", {
                email: loginData.email,
              })
            );
            return;
          } else {
            // 신규가입처리
            const param = {
              username: loginData.email.trim(),
              email: loginData.email,
              password: (loginData.id + new Date().getTime()).trim(),
              nickname: loginData.nickname.trim(),
              // username: $.trim(loginData.email.trim()),
              // email: $.trim(loginData.email),
              // password: $.trim(loginData.id + new Date().getTime()),
              // nickname: $.trim(loginData.nickname),

              is_email_verified: "Y", // 소셜로그인시 인증 Y

              first_name: loginData.first_name,
              last_name: loginData.last_name,
              profile_img: loginData.profile_img,


              site: this.user.getCurrentSite(),
            };
            await this.http.post("auth/regist", param);

            // 일반로그인 인증처리
            const res: any = await this.http.post(`auth/login`, param);
            await this.user.setToken(res.data.token);

            // 소셜연결
            const connect_res = await this.http.post(
              `auth/social/connect`,
              loginData
            );

            // 성공시 페이지 처리
            this.user.loginSuccessRedirect();
          }
        }
      } catch (e) {
        this.ctrl.alert(e.message);
      } finally {
        loading.dismiss();
      }
    }
  };
  ////////////////////////////////////////////////////////////////////////////////////

  public util = {
    randomRange: (n1, n2) => {
      return Math.floor(Math.random() * (n2 - n1 + 1) + n1);
    },

    s3Url: (key, options?, is_private?) => {
      if (!key) {
        return "assets/img/noimage.png"; // no image
      }

      if (key.indexOf("http") == 0) {
        // 그대로 패스
        return key;
      }

      // 옵션정리
      options = options || {};
      const resize_type = options.t || "fill"; // resize_type: fit, fill, auto
      const gravity = options.g || "sm"; // smart
      const width = options.w || 324;
      const height = options.h || 0;
      const enlarge = options.en || 0;

      // 최종 image_proxy
      let imgproxy_endpoint;

      if (is_private) {
        imgproxy_endpoint = config.s3_private;
      } else {
        imgproxy_endpoint = config.s3_public;
      }
      const image_proxy_url =
        `${imgproxy_endpoint}/${resize_type}/${width}/${height}/${gravity}/${enlarge}/plain/s3://` +
        key;
      return image_proxy_url;
    },

    // name, 1, day
    getDiffCheckTime: (name, amount, unit) => {
      // 해당날짜 이상이면 초기화
      const time = localStorage.getItem(name);
      if (time) {
        return false;
      } else {
        return true;
      }
    },
    setCheckItem: (name) => {
      localStorage.setItem(name, moment().toDate().toString());
    },
    getUrlParams: (search) => {
      const hashes = search.slice(search.indexOf("?") + 1).split("&");
      const params = {};
      hashes.map((hash) => {
        const [key, val] = hash.split("=");
        params[key] = decodeURIComponent(val);
      });
      return params;
    },
    getSpacedBarcode(text) {
      if (text) {
        // return text.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
        return text.replace(/(.{4})/g, "$1 ").trim();
      } else {
        return "";
      }
    },
    nullCheck: (data, msg) => {
      if (!data) {
        this.ctrl.alert(msg);
        return false;
      } else {
        return true;
      }
    },

    removeSpace: (val) => {
      return val.replace(/\s/g, "");
    },
    extractNumber: (val) => {
      return val.replace(/[^0-9]/g, "");
    },
    number: (val) => {
      // 1000단위 콤마 찍기
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    validEmail: (email) => {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    getFileameFromUrl: (url) => {
      const parseUrl = url.split("/"); //   '/' 로 전체 url 을 나눈다
      const parseUrlLength = parseUrl.length;
      const filename = parseUrl[parseUrlLength - 1]; // 나누어진 배열의 맨 끝이 파일명이다
      return filename;
    },
    getPushIcon: () => {
      // tslint:disable-next-line:no-console
      // console.trace();
      if (this.currentTab == "inbox") {
        if (this.cache.badge.push > 0) {
          return "assets/img/icon_inbox_mo_on_push.svg";
        } else {
          return "assets/img/icon_inbox_mo_on.svg";
        }
      } else {
        if (this.cache.badge.push > 0) {
          return "assets/img/icon_inbox_mo_off_push.svg";
        } else {
          return "assets/img/icon_inbox_mo_off.svg";
        }
      }
    },
  };

  public ctrl = {
    loading: async (message?) => {
      await this.translate.get(message || "MSG_LOADING").toPromise();
      const loading = await this.loadingCtrl.create({
        message: message
          ? this.getTranslate(message)
          : this.getTranslate("MSG_LOADING"),
      });
      await loading.present();
      return loading;
    },
    alert: async (message) => {
      await this.translate.get(message).toPromise();
      const alert = await this.alertCtrl.create({
        message: this.getTranslate(message),
        buttons: [
          {
            cssClass: "primary",
            text: this.getTranslate("BTN_OK"),
          },
        ],
      });

      await alert.present();
    },

    alertError: async (e) => {
      let message = e.message;
      await this.translate.get(message).toPromise();

      // 다국에 에러메세지 처리
      switch (message) {
        case "NOT_FOUND_USER":
          message = this.getTranslate("ERR_CHECK_EMAIL_PW"); // 'Please check email and password.';
          break;
        case "PASSWORD_ERROR":
          message = this.getTranslate("ERR_CHECK_EMAIL_PW");
          break;
        case "ERR_CURRENT_PW":
          message = this.getTranslate("ERR_CURRENT_PW");
      }

      const alert = await this.alertCtrl.create({
        message: this.getTranslate(message),
        buttons: [
          {
            cssClass: "primary",
            text: this.getTranslate("BTN_OK"),
          },
        ],
      });

      await alert.present();
    },

    alertWithTitle: async (title, message) => {
      const alert = await this.alertCtrl.create({
        header: this.getTranslate(title),
        message: this.getTranslate(message),
        cssClass: "message",
        buttons: [
          {
            text: this.getTranslate("BTN_OK"),
          },
        ],
      });

      await alert.present();
    },
    alertCallback: async (message, cb) => {
      const alert = await this.alertCtrl.create({
        message: this.getTranslate(message),
        buttons: [
          {
            cssClass: "primary",
            text: this.getTranslate("BTN_OK"),
          },
        ],
      });
      alert.onDidDismiss().then(() => {
        cb();
      });

      await alert.present();
    },
    alertConfirm: async (message, yes, no?) => {
      const alert = await this.alertCtrl.create({
        message: this.getTranslate(message),
        backdropDismiss: false,
        buttons: [
          {
            text: this.getTranslate("BTN_NO"),
            cssClass: "light",
            handler: () => {
              if (no) {
                no();
              }
            },
          },
          {
            text: this.getTranslate("BTN_YES"),
            cssClass: "primary",
            handler: () => {
              yes();
            },
          },
        ],
      });
      alert.onDidDismiss().then((res) => {
     
      });

      await alert.present();
    },
    alertPurchase: async () => {
      const alert = await this.alertCtrl.create({
        message: `<div class="purchaseModal">
          <div class="purchaseModal__top">
            <ion-spinner></ion-spinner>
            ${this.getTranslate("CHARGING")}
          </div>
          <div class="purchaseModal__bottom">
            ${this.getTranslate("Charging_info")}
          </div>
        </div>`,
        backdropDismiss: false
      })

      await alert.present();
      return alert;
    },
    toast: async (message) => {
      const toast = await this.toastCtrl.create({
        message,
        cssClass: "gt-toast-message",
        position: "middle", // "bottom" | "middle" | "top"
        duration: 3000000,
        buttons: [
          {
            text: this.getTranslate("BTN_DONE"),
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ],
      });
      toast.present();
    },

    openModalNoAnimate: async (_page, _prop, cb?) => {
      const modal = await this.modalCtrl.create({
        component: _page,
        componentProps: _prop,
        animated: false,
      });
      modal.present();

      modal.onDidDismiss().then((res) => {
        if (cb) {
          cb(res);
        }
      });
    },
    openModal: async (_page, _prop, cb?) => {
      const modal = await this.modalCtrl.create({
        component: _page,
        componentProps: _prop,
        cssClass: "auto-height",
      });
      modal.present();

      modal.onDidDismiss().then((res) => {
        if (cb) {
          cb(res);
        }
      });
    },
    openFullModal: async (_page, _prop, cb?) => {
      const modal = await this.modalCtrl.create({
        component: _page,
        componentProps: _prop,
        cssClass: "modal-fullscreen",
        animated: false,
        showBackdrop: true,
        backdropDismiss: false,
      });
      modal.present();

      modal.onDidDismiss().then((res) => {
        if (cb) {
          cb(res);
        }
      });
    },
    openTransparentModal: async (_page, _prop, cb?) => {
      const modal = await this.modalCtrl.create({
        component: _page,
        componentProps: _prop,
        animated: false,
        backdropDismiss: false,
        cssClass: "modal-transparent",
      });
      modal.present();

      modal.onDidDismiss().then((res) => {
        if (cb) {
          cb(res);
        }
      });
    },

    openInlineModal: async (pageName, pageObject?, addClass?) => {
      const _modal = await this.modalCtrl.create({
        component: pageName,
        componentProps: pageObject,

        showBackdrop: true,
        backdropDismiss: false,
        cssClass: "modal-inline " + addClass,
      });
      await _modal.present();

      return _modal;
    },
    openInlineNoAnimationModal: async (pageName, pageObject?, addClass?) => {
      const opts = {
        component: pageName,
        componentProps: pageObject,
        animated: false,
        showBackdrop: true,
        backdropDismiss: false,
        cssClass: "modal-inline " + addClass,
      };
      const _modal = await this.modalCtrl.create(opts);
      _modal.present();
      return _modal;
    },
  };

  /*
        service for app customized
    */
  public service = {
    convertLangToCountry(lang) {
      try {
        let country = "en";
        // const find_country = _.find(SiteCategory, { lang: lang.toLowerCase() });
        // console.log(find_country);
        const find_country = SiteCategory.find(val =>  val.lang == lang.toLowerCase() );

        if (find_country) {
          country = find_country.country;
        }
        return country;
      } catch (e) {
        return "en";
      }
    },
    // 국가명(주로 url)에서 언어(사이트)로 변경
    convertCountryToLanuage(country) {
      try {
        let lang = "EN";
        // const find_lang = _.find(SiteCategory, {
        //   country: country.toLowerCase(),
        // });
      
        const find_lang = SiteCategory.find((val) =>  val.country == country.toLowerCase());
        if (find_lang) {
          lang = find_lang.value;
        }
        return lang;
      } catch (e) {
        return "EN";
      }
    },
    // 홈: 기본데이터
    getMainList: () => {
      return new Promise((resolve, reject) => {
        this.httpClient.get(`${environment.api_endpoint}api/v1/title-category/main`)
          .subscribe(response => {
            resolve(response);
          })
          
      })
      // const res:any = this.http.get(`api/v1/title-category/main`);
      // this.cache.main_list = res.data;
      // return res.data;
    },

    // 홈: 사용자 데이터
    getMainUserList: () => {
      return new Promise((resolve, reject) => {
        this.httpClient.get(`${environment.api_endpoint}api/v1/title-category/main-user`)
          .subscribe((response) => {
            resolve(response);
          })
      })
      // if (this.user.isLogin()) {
        // const res: any = this.http.get(`api/v1/title-category/main-user`);
        // return res.data;
        // this.cache.main_user_list = [
        //   {
        //     home_template: "user",
        //     category_name: "RECENT",
        //     title_category_item: res.data.recent,
        //   },
        //   {
        //     home_template: "user",
        //     category_name: "SUBSCRIBED",
        //     title_category_item: res.data.subscribe,
        //   },
        // ];
        // console.log("메인유저갱신");
      // } else {
      //   this.cache.main_user_list = [];
        // console.log("메인유저초기화 - 로그아웃 ");
      // }
    },

    changeLanguage: async (lang, cb) => {
      await this.translate.use(lang);
      let loading;
      try {
        loading = await this.ctrl.loading("LOADING_CHANGE_LANGUAGE");
        await this.user.changeCurrentSite(lang);
        if (this.user.isLogin()) {
          await this.http.post(`api/v1/user/change-site`, { site: lang }); // 유저 기본언어셋 변경
        }
        cb();

        return true;
      } catch (e) {
        this.ctrl.alertError(e);
      } finally {
        loading.dismiss();
      }
    },

    encrypt: (key) => {
      return sha256(key);
    },

    initBanner: async () => {
      try {
        const res = await this.http.get(`api/v1/banner/all`);
        const result = res.data;

        const top = [];
        // for(let item of result.top ){
        if (result.top.length > 0) {
          const item = result.top[0];
          item.banner_img = this.util.s3Url(item.banner_img, {
            w: 1920,
            h: 65,
          });
          item.banner_mobile_img = this.util.s3Url(item.banner_mobile_img, {
            w: 800,
            h: 96,
          });
          top.push(item);
        }

        const main = [];
        for (const item of result.main) {
          item.banner_img = this.util.s3Url(item.banner_img, {
            w: 1200,
            h: 360,
          });
          item.banner_mobile_img = this.util.s3Url(item.banner_mobile_img, {
            w: 608,
            h: 553,
          });
          main.push(item);

          // 메인 항상 2개해야 화면갱신됨
          if (result.main.length == 1) {
            main.push(item); // 동일베너 하나더 넣어줌
          }
        }
        const bottom = [];
        for (const item of result.bottom) {
          item.banner_img = this.util.s3Url(item.banner_img, {
            w: 980,
            h: 234,
          });
          item.banner_mobile_img = this.util.s3Url(item.banner_mobile_img, {
            w: 608,
            h: 264,
          });
          bottom.push(item);
        }
        const event = [];
        for (const item of result.event) {
          item.banner_img = this.util.s3Url(item.banner_img, {
            w: 980,
            h: 234,
          });
          item.banner_mobile_img = this.util.s3Url(item.banner_mobile_img, {
            w: 608,
            h: 264,
          });
          event.push(item);
        }

        this.cache.banner.top = top;
        this.cache.banner.main = main;
        this.cache.banner.bottom = bottom;
        this.cache.banner.event = event;

        // this.cache.banner = {
        //   top,
        //   main,
        //   bottom,
        //   event,
        // };

        // this.ngZone.run(()=>{})
      } catch (e) {
        console.log("베너호출실패");
      }
    },
    initGenre: async () => {
      try {
        const res = await this.http.get(`api/v1/title-category/genre-list`);
        const list = res.data;

        // 모두보기 추가
        this.code.genreList = [];
        this.code.genreList.push({ code: "", text: "ALL" });
        for (const item of list) {
          this.code.genreList.push({ code: item.code, text: item.text });
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    initSupportLanguage: async () => {
      try {
        const res = await this.http.get("api/v1/translate/support");
        this.cache.supportLanguageList = res.data;
      } catch (e) {
        console.log("다국어정보 호출 실패");
      }
    },
    appDownloadFromWeb: () => {
      const apple_id = config.store.apple_id;
      const package_id = config.store.package_id;

      const ua = navigator.userAgent;
      let storeUrl = "";
      const checker = {
        iphone: ua.match(/(iPhone|iPod|iPad)/),
        android: ua.match(/Android/),
      };
      if (checker.android) {
        storeUrl =
          "https://play.google.com/store/apps/details?id=" +
          package_id +
          "&launch=true";
      } else if (checker.iphone) {
        storeUrl = "https://itunes.apple.com/app/id" + apple_id;
      } else {
        // PC
        storeUrl = "https://app.giftistar.net";
      }
  

      if (checker.android) {
        location.href = storeUrl;
      } else if (checker.iphone) {
        location.href = storeUrl;
      }
    },

    bannerLink: (item) => {
  
      if (item.banner_link_type == "inside") {
     
        // split처리필요
        const _url = item.banner_link_url;
        if (_url.indexOf("?") > -1) {
          const _urlArr = _url.split("?");
          this.go(_urlArr[0]);
        } else {
          this.go(_url);
        }
      } else {
        window.open(item.banner_link_url, "_system");
      }
    },

    pushRedirect: async (data) => {
      // 일단읽음처리
      if (data.hasOwnProperty("pushId")) {
        //   await Parse.Cloud.run('setPushRead',{pushId: data.pushId});
      }
      if (data.hasOwnProperty("url")) {
        try {
          if (data.url) {
            this.ngZone.run(() => {
              const site = data.url.slice(0, data.url.indexOf("/"));
              this.service.convertLangToCountry(site);
              this.go(data.url);
            });
          } else {
            console.log("URL정보없음");
          }
        } catch (e) {
          // alert("잘못된 푸시링크입니다.");
          alert(e.message);
        }
      } else {
        console.log("url is empty");
      }
    },
    getFileameFromUrl: (url) => {
      const parseUrl = url.split("/"); //   '/' 로 전체 url 을 나눈다
      const parseUrlLength = parseUrl.length;
      const filename = parseUrl[parseUrlLength - 1]; // 나누어진 배열의 맨 끝이 파일명이다
      return filename;
    },
    validEmail: (email) => {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
  };
  /*
      리스트 페이지 모듈
    */
  public page = {
    loadStart: (pageData) => {},
    loadComplete: (pageData, result) => {
      let dataset;
      try {
        pageData.loading = false;

        if (pageData.refresher) {
          // this.pageData.refresher.target.complete();
          pageData.refresher.target.disabled = true;
          pageData.refresher.target.complete();
          setTimeout(() => {
            pageData.refresher.target.disabled = false;
          }, 100);
        }

        try {
          if (pageData.infiniteScroll) {
            if (pageData.infiniteScroll.target) {
              pageData.infiniteScroll.target.complete();
            }
          }
        } catch (e) {}
        if (result.length < pageData.size) {
          pageData.more = false;
        } else {
          pageData.more = true;
        }
        // dataset = this.generalPage.findIndex((data) => data.genre === pageData.genre);
        // if (dataset >= 0) {
        //   this.generalPage[dataset].genre = pageData.genre;
        //   this.generalPage[dataset].size = pageData.list.length;
        //   this.generalPage[dataset].more = pageData.more;
        // } else {
        //   this.generalPage.push({
        //     genre: pageData.genre,
        //     size: pageData.list.length,
        //     more: pageData.more
        //   })
        // }
 
      } catch (e) {
        console.error("loadComplete error", e.message);
      }
    },
    refresherComplete: (refresher) => {
      try {
        refresher.target.disabled = true;
        refresher.target.complete();
        setTimeout(() => {
          refresher.target.disabled = false;
        }, 100);
      } catch (e) {
        console.error("loadComplete error", e.message);
      }
    },
    setGenreValue: (pageData) => {

    }
  };

  public getTranslate(key, param?) {
    return this.translate.instant(key, param);
  }

  public goPush(url, data?) {
    if (data) {
      this.router.navigate([url, data]);
    } else {
      this.router.navigate([url]);
    }
  }

  public goWithLogin(url, data?) {
    // 로그인여부체크
    const isLogin = false;
    if (isLogin) {
      this.go(url, data);
    } else {
      this.go("/login");
    }
  }

  public goRelative(url, route) {
    this.router.navigate([url], { relativeTo: route });
  }

  // 페이지 전체 이동
  public async go(url, param?) {
    if (param) {
      // const navigationExtras: NavigationExtras = {
      //     queryParams: param
      // };
      this.navCtrl.navigateForward([url, param], {
        animated: this.env.isCordova && this.env.isIOS,
      });
      // this.navCtrl.navigateForward([url], navigationExtras); // { queryParams : param, animated: false });
      // this.router.navigate([url, param]);
    } else {
      // this.navCtrl.navigateForward([url], { animated: this.env.isCordova && this.env.isIOS })
      this.navCtrl.navigateForward([url], { animated: false });
      // this.router.navigate([url]);
    }
  }

  public goBack() {
    // alert(this.previousUrl);
    if (this.previousUrl !== "/") {
      this.navCtrl.back({ animated: false });
    } else {
      this.goHome();
    }
  }

  public goReplace(url) {
    this.navCtrl.navigateRoot(url);
    // this.router.navigate([url], { replaceUrl: true })
  }

  public goSkip(url, data?) {
    if (data) {
      this.router.navigate([url, data], { replaceUrl: true });
    } else {
      this.router.navigate([url], { replaceUrl: true });
    }
  }

  public goHome() {
    this.events.publish("home_enter", {});
    this.navCtrl.navigateRoot("/");
  }

  public goReload(url: string) {
    this.router
      .navigateByUrl("/blank", {
        skipLocationChange: true,
      })
      .then(() => {
        this.router.navigateByUrl(url);
      });
  }

  public goComic(item) {
    const title_key = item.title_slug || item.title_id;

    const _site = this.service.convertLangToCountry(item.site);
    this.go(_site + "/comic/" + title_key);
  }

  public setRedirectUrl() {
    localStorage.setItem(KeyStore.redirect, window.location.pathname);
  }

  public goAppDownload(platform) {
    if (platform == "ios") {
      location.href = "https://itunes.apple.com/app/id" + config.store.apple_id;
    } else {
      location.href =
        "https://play.google.com/store/apps/details?id=" +
        config.store.package_id;
    }
  }

  public getNavParam(field) {
    return this.route.snapshot.params[field];
  }

  public setTab(tab, value, cb) {
    tab.current = value;

    if (cb) {
      cb();
    }
  }

  public errorReporting(title, error) {
    const message = error.message || JSON.stringify(error);
    this.ctrl.alert(message);
  }

  public initHideTabEvent() {
    this.router.events
      .pipe(filter((e) => e instanceof onanimationend))
      .subscribe((e: any) => {
        this.showHidetabs(e);
      });
  }

  private showHidetabs(e: NavigationEnd) {
    const page = e.urlAfterRedirects.split("?")[0];
    this.showTabBarPages.indexOf(page) > -1 ? this.showTabs() : this.hideTabs();
  }

  public hideTabs() {
    const tabBar = document.getElementById("myTabBar");
    if (tabBar) {
      if (tabBar.style.display !== "none") {
        tabBar.style.display = "none";
      }
    }
  }

  public showTabs() {
    const tabBar = document.getElementById("myTabBar");
    if (tabBar) {
      if (tabBar.style.display !== "flex") {
        tabBar.style.display = "flex";
      }
    }
  }




  /**
   * create by Team1985
   */

   IconNewUp(opendate: any, days: number) {
      let newIcons = false;
      //최신업데이트
      const _diff = differenceInCalendarDays(new Date(opendate), new Date());
      newIcons = _diff > days ? true : false; 
      
      return newIcons;
  }
}
