export const environment = {
  production: true,
  auto_update: false,
  appflow_channel: "oper",

  api_endpoint: "https://beta-api.netcomics.com/",
  s3_public: "https://cdn.netcomics.com/img",
  s3_private: "https://content.netcomics.com/data",

  paypal_client_id:
    "AfU65diRJvoG412gFDX_7_5xIUNEjZfvZyitfgeQvaNF5lG432IrsnBW8nBZn9vHs8YGCDT3LBSVC40k", // live
  stripe_client_id: "pk_live_cNAXWRE9vYLY84HVwlSevTFV", // live

};
