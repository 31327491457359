import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppService } from './app.service';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { COMPOSITION_BUFFER_MODE, FormsModule, ReactiveFormsModule } from '@angular/forms';



import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SafePipe } from './safe.pipe';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider
} from 'angularx-social-login';

import { config } from 'src/config/config';
import { HttpErrorInterceptor } from 'src/shared/interceptors/http-error.interceptor';
import { TokenInterceptor } from 'src/shared/interceptors/token.interceptor';
import { AdultModalPage } from './modals/adult-modal/adult-modal.page';

import { BuyEcashModalPage } from './modals/buy-ecash-modal/buy-ecash-modal.page';
import { ViewerPayModalPage } from './modals/viewer-pay-modal/viewer-pay-modal.page';
import { SelectLanguageModalPage } from './modals/select-language-modal/select-language-modal.page';
import {NgxPaginationModule} from 'ngx-pagination';
import { DecimalPipe } from '@angular/common';


const pages = [ AppComponent, AdultModalPage, BuyEcashModalPage, ViewerPayModalPage, SelectLanguageModalPage];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, config.api_endpoint  + 'api/v1/translate/lang/', '');
}

@NgModule({
    declarations: pages,
    imports: [
        BrowserModule,
        IonicModule.forRoot({
            mode: 'ios',
            backButtonDefaultHref: 'home',
            backButtonIcon: 'chevron-back-outline',
            backButtonText: '',
            swipeBackEnabled: true,
            animated: true,
            rippleEffect: false,
        }),
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        SocialLoginModule,
        TranslateModule.forRoot({
            defaultLanguage: 'EN',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NgxPaginationModule
    ],
    providers: [
        SocialSharing,
        SafePipe,
        AppService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: TokenInterceptor,
                multi: true,
            },
            {
                provide: HTTP_INTERCEPTORS,
                useClass: HttpErrorInterceptor,
                multi: true,
            },
            {
                provide: COMPOSITION_BUFFER_MODE,
                useValue: false,
            },
        ],
        // social-login
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(config.social.facebook_client_id),
                    },
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(config.social.google_client_id),
                    },
                ],
            } as SocialAuthServiceConfig,
        },
        // social-login@
        DecimalPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
