import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanLoad, UrlSegment} from '@angular/router';
import {AppService} from 'src/app/app.service';

@Injectable({
    providedIn: 'root'
})
export class UnAuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private app: AppService
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const redirect = state.url.replace('/', '');
        // console.log('canActivate window.location  = ' + window.location);
        // console.log('canActivate state.url  = ' + state.url);
        // console.log('canActivate next.url  = ' + next.url);
        // console.log('canActivate redirect = ' + redirect);
        const url: string = state.url;
        return this.isAuthenticated(next, url);
    }


    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const redirect = state.url.replace('/', '');
        // console.log('canActivateChild window.location  = ' + window.location);
        // console.log('canActivateChild state.url  = ' + state.url);
        // console.log('canActivateChild next.url  = ' + next.url);
        // console.log('canActivateChild redirect = ' + redirect);
        const url: string = state.url;
        return this.isAuthenticated(next, url);
    }

    // 로그인 안햇을때만 조회 가능
    isAuthenticated(route: ActivatedRouteSnapshot, url: any): boolean {
      console.log('isAuthenticated');
      console.log(route.url[0].path);
        let is_login = this.app.user.isLogin();

        if (is_login) {
          if ( route.url[0].path == 'regist' ) {
            this.app.ctrl.alert( this.app.getTranslate('ALREADY_LOGIN'));
          }
          this.app.goReplace('/');
          return false;
        } else {
            return true;
        }
    }


}
