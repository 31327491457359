import { Component, Inject, OnInit } from '@angular/core';

import { fromEvent, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { AppService } from 'src/app/app.service';
import { ModalController } from '@ionic/angular';
import { config } from 'src/config/config';
import { DOCUMENT } from '@angular/common';

declare var Stripe;


/*
  ngx-stripe 
  반드시 ssl 로 접속해야한다. 

  ionic ssl generate
  ionic serve --ssl --ssl-cert /User/seman/.ionic/ssl/cert.pem --ssl-key /User/seman/.ionic/ssl/key.pem
  ionic serve --ssl 
  https://richnologies.gitbook.io/ngx-stripe/examples
*/
 
@Component({
  selector: 'app-stripe-modal',
  templateUrl: './stripe-modal.page.html',
  styleUrls: ['./stripe-modal.page.scss'],
})
export class StripeModalPage implements OnInit {
  card:any;  
  stripe;
  ecash = 29.99; 

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private app:AppService,
    private modalCtrl: ModalController,
  ) {

  }

  async ngOnInit(){
    await this.initiate().subscribe(
      () =>{
        this.stripe = Stripe( config.stripe_client_id ); //키값이 필요함 
        console.log('언제 시작됨? 결제가?');
        this.setupStripe();
    
      }); 
  }

   initiate(): Observable<void>{
     try{
      const rstripeScriptElement = this.document.getElementById('stripe-script');
      this.document.head.removeChild(rstripeScriptElement);
     }catch(e){}

    const stripeScriptElement: HTMLScriptElement = this.document.createElement('script');

    stripeScriptElement.src = `https://js.stripe.com/v3/`;
    stripeScriptElement.id = 'stripe-script';

    this.document.head.appendChild(stripeScriptElement);

    return fromEvent<void>(stripeScriptElement, 'load').pipe(first()); 
  }

  public remove(): void {
    const stripeScriptElement = this.document.getElementById('stripe-script');
    this.document.head.removeChild(stripeScriptElement);
  }
  
 
  
  setupStripe() {
    const elements = this.stripe.elements(); 

    this.card = elements.create('card', {
      hidePostalCode: true,
      style: {
        base: {
          backgroundColor: 'white', 

          iconColor: '#666EE8',
          color: '#31325F',
          fontWeight: 'bold',
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: 'antialiased',
          padding:'18px',
          fontSize: '16px',
          '::placeholder': {
            color: '#707070',
          }, 
        },
        invalid: {
            color: '#FF4500',
            iconColor: '#fa755a'
        }
    }
    });

    setTimeout(() => {
        this.card.mount('#cards');

        this.card.addEventListener('change', event => {
            const displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        // const form = document.getElementById('payment-form');
        // form.addEventListener('submit', event => {
        //     event.preventDefault();

        //     // this.stripe.createToken(this.card)
        //     this.stripe.createSource(this.card).then(result => {
        //         if (result.error) {
        //             const errorElement = document.getElementById('card-errors');
        //             errorElement.textContent = result.error.message;
        //         } else {
        //             console.log(result);
        //         }
        //     });
        // });
    });

}


  close(data?){
    this.modalCtrl.dismiss(data);
  } 

  async createPaymentIntent(amount){
    try{
      const url =`api/v1/stripe/create-payment-intent`;
      return await this.app.http.post(url, { amount: amount });
    }catch(e){
      alert(e.message);
    }
  }

  async pay() {
    let loading = await this.app.ctrl.loading("PAYMENT_ING")
    try{
      const res:any = await this.createPaymentIntent( Number(this.ecash) * 100 ); //센트단위결제 
      console.log("createPaymentIntent", res ) 
      
      const result = await this.stripe.confirmCardPayment( res.client_secret, {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.app.user.current.email,
          },
        }
      });

      if(result.error) {
        console.error('got stripe error', result.error);
        this.app.ctrl.alert(result.error.message);
      } else {
        // console.log( result.paymentIntent);
        // alert("로그" + result.paymentIntent.status);
        console.log('payment succeeded');
        this.close( result.paymentIntent )
      }
    }catch(e){
      alert("에러")
    }finally{
      loading.dismiss();
    }
  }
 

}