<ion-header>
  <ion-toolbar>
    <ion-title>Stripe</ion-title>

    <ion-buttons slot="end" >
      <ion-button (click)="close()">
        <ion-icon src="assets/icon/close-circle-outline.svg"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>
</ion-header>

<ion-content>

  <div class="stripe-wrapper">

    
    <h2>Credit or debit card</h2>
    <br><br>
    <div> 
      <div id="cards" style="padding:12px 18px;background-color: white;border:2px solid gray;border-radius:5px;"></div>
      
      <br><br>

      <div id="card-errors" style="color:orangered;padding-bottom:30px;"></div>

      <ion-button expand="block"  type="submit" (click)="pay()">
        ${{ecash}} PAY
      </ion-button>
    </div> 

    <br><br>
       
  </div>


</ion-content>
