import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-adult-modal',
  templateUrl: './adult-modal.page.html',
  styleUrls: ['./adult-modal.page.scss'],
})
export class AdultModalPage implements OnInit {

  constructor(
    public app: AppService,
    private modalCtrl:ModalController,
  ) { }

  ngOnInit() {
  }

  close(data?){
    this.modalCtrl.dismiss(data);
  }

  async agree(){
    //성인여부 업데이트 
    let loading = await this.app.ctrl.loading('MSG_UPDATE');
    try{
      await this.app.http.post('api/v1/user/confirm-adult');
      await this.app.user.fetchUser(); //유저정보 갱신 
    }catch(e){
    }finally{
      loading.dismiss();
      this.modalCtrl.dismiss("YES");
    }
  }

}
