<ion-header>
  <ion-toolbar color="light">
    <ion-title *ngIf="isReview">
      {{'TITLE_REVIEW' | translate }}  
    </ion-title> 
    <ion-title *ngIf="!isReview">
      {{'TITLE_CHECKOUT' | translate }} 
    </ion-title>
 

    <ion-buttons slot="end" >
      <ion-button (click)="close()">
        <ion-icon src="assets/icon/close-circle-outline.svg"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>
</ion-header>


<ion-content>


  <!-----------------------------
     리뷰창 
    ----------------------------->
 
<div class="episode_review" >
  
  <!-- 리뷰남기기 -->
  <div class="comic_review comic_review_modal" *ngIf="isReview && currentChapter">
    <div class="comic_review comic_review_modal review_all">
        <ul class="comic_review_list">
            <li>
                <div class="review_content" style="padding:0px 0px 10px;">
                    <div class="review_user">

                        <img src="assets/img/review_star_5.png" alt="별점5개" class="review_user_star">

                        <div class="review_user_id">idid*****</div>

                        <div class="review_user_date">Sep 05, 2019</div>
                    </div>
                    <div class="review_text">

                        <i class="i_best_review"></i>
                        Such a good story, it's sad that it's now done. I hope we'll see Such a good story, it's sad that it's now done. I hope we'll see  it on
                        anime :) it on anime :)  it on anime :) it on anime :)
                    </div>
                    <!-- 작품명-챕터번호
                    <div class="review_from">
                        The Maid and the Vampire - Ch.4
                    </div> -->
                   
                    <!-- 리뷰추천   
                    <button class="review_recom btn_toggle">5</button>
                    이 리뷰에 달린 대댓수  
                    <button class="review_comment btn_toggle">2</button>
                    -->
                </div>
                 
            </li>
        </ul>
    </div> 

    <div class="comic_review_star" style="padding-top:0;text-align: center;">
      <h4 style="margin:0 auto;">Please rate and review this chapter</h4>
      <div class="comic_review_star">
          <h6>  {{ chapter.title }} - {{ 'VAR_CHAPTER' | translate:{ch: chapter.chapter_no} }}</h6>
          <div>

              <span [ngClass]="{star_on: reviewForm.rating >=1, star_off: reviewForm.rating < 1}" (click)="setRating(1)"></span>
              <span [ngClass]="{star_on: reviewForm.rating >=2, star_off: reviewForm.rating < 2}" (click)="setRating(2)"></span>
              <span [ngClass]="{star_on: reviewForm.rating >=3, star_off: reviewForm.rating < 3}" (click)="setRating(3)"></span>
              <span [ngClass]="{star_on: reviewForm.rating >=4, star_off: reviewForm.rating < 4}" (click)="setRating(4)"></span>
              <span [ngClass]="{star_on: reviewForm.rating >=5, star_off: reviewForm.rating < 5}" (click)="setRating(5)"></span>
          </div>
      </div>
      <textarea name="" id="review_text" [(ngModel)]="reviewForm.content" class="review_textarea"></textarea>
      <button class="review_submit" style="width:100%;" >{{'BTN_SUBMIT' | translate}}</button>

  </div>
</div> 




    <!-----------------------------
     결제창 
    ----------------------------->
  <div class="modal-content-wrapper"> 

    <div class="message-box">
      <b>{{chapter.title.title_name }} 
        <span *ngIf="chapter.chapter_no>0"> - {{'VAR_CHAPTER' | translate:{ch: chapter.chapter_no} }}</span>
        <span *ngIf="chapter.chapter_name"> <span *ngIf="chapter.chapter_no>0"> - </span> {{ chapter.chapter_name }}</span>
      </b>
      <br>
      <!-- <div>
        This is the last page.
      </div> -->
      <!-- Would you like to continue?  -->
      {{'MSG_VIEW_CONTINUE' | translate}}
    </div>

    <br><br>


    <!--버튼-->
    <div class="buy-buttons-wrapper" *ngIf="!is_order">     
      <ion-button *ngIf="chapter.rent"  class="mr new-btn" expand="block" fill="solid" color="primary" (click)="purchase('rent', chapter.rent )">{{ 'RENT' | translate}} {{chapter.rent |  number:'1.2-2'}}</ion-button>
      <br>
      <ion-button *ngIf="chapter.buy" class="new-btn" expand="block" color="primary" (click)="purchase('buy', chapter.buy )">{{ 'BUY' | translate}} {{chapter.buy |  number:'1.2-2'}}</ion-button>
    </div>

    <div class="buy-buttons-wrapper" *ngIf="is_order">     
      <ion-button  class="new-btn" expand="block" color="primary" (click)="continueChapter()">{{'CONTINUE' | translate}}</ion-button>
    </div>
    <!--버튼@-->

    
  </div>
  
</div>

 
</ion-content>
