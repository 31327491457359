import { environment } from "src/environments/environment";

export const config = {
  version: "2.8.3", // native version
  build: 22, // web (appflow) version

  production: environment.production,

  api_endpoint: environment.api_endpoint,
  s3_public: environment.s3_public,
  s3_private: environment.s3_private,
  // api_endpoint: 'http://3.37.79.181:8080/',
  // s3_public: 'http://3.37.79.181:8888/img',
  // s3_private: 'http://3.37.79.181:8889/data',

  onesignal: {
    app_id: "e7423ce5-bc48-4322-899f-bf2df1f40e5f",
    sender_id: "696503919612",
  },

  paypal_client_id: environment.paypal_client_id,
  stripe_client_id: environment.stripe_client_id,

  social: {
    facebook_client_id: "113860685727633",
    google_client_id:
      "696503919612-lv6ug2vrocc0nov0muisdk79ahfrveh0.apps.googleusercontent.com",
  },

  auto_update: environment.auto_update,
  appflow: {
    channel: environment.appflow_channel,
    appId: "netcomics",
    updateMethod: "none",
    host: "https://beta-api.netcomics.com",
    maxVersions: 2,
  },

  store: {
    apple_id: "1401100831",
    package_id: "com.netcomics.app",
  },
};
