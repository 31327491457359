<div class="inner-content modal-layer2 show-modal modal-language" id="modalLanguage">
  <div class="modal-inner">
      <div class="modal-content">
       
          <form action="">
              <ul class="modal-select" *ngFor="let item of this.app.cache.supportLanguageList">

                  <li class="modal-option" (click)="close(item.code)">
                      <input type="radio" name="language" id="{{item.name}}">
                      <label for="{{item.name}}">{{item.name}}</label>
                  </li>
              </ul>
          </form>

          <button class="btn-modal-close" (click)="close()"><img src="assets/img/btn_modal_close.svg" alt=""></button>

      </div>
  </div>                         
</div>
