/*
    DB 코드관리되거나 다양한 곳에 의미있는 변수는 enum생성 
*/
export enum KeyStore {
    token = 'ncx.user.token',
    user = 'ncx.user.data',
    site = 'ncx.user.site',
    adult = 'ncx.user.adult',
    did = 'ncx.user.did',
    redirect = 'ncx.redirect'
}
export enum PayMethod {
    paypal = 'paypal',
    stripe = 'stripe',
    android = 'android',
    ios = 'ios',
    test = 'test',

}
export enum Role {
    user = 'user',
    staff = 'staff',
    admin = 'admin'
}

export enum Gender {
    female = 'F',
    male = 'M',
}

export enum ViewerMode {
    scroll = 'scroll',
    normal = 'normal',
    normal_daul = 'normal_dual',
    reverse = 'reverse',
    reverse_daul = 'reverse_dual',
}