// noinspection ES6MissingAwait

import { Component, QueryList, ViewChild, ViewChildren } from "@angular/core";

import {
  IonRouterOutlet,
  ModalController
} from "@ionic/angular";
import { AppService } from "./app.service";
import { SocialAuthService, SocialUser } from "angularx-social-login";
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";

import { Events } from "./services/events";
import { SocialLogin } from "src/shared/entities/social-login";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  loadingText = true;

  constructor(
    public app: AppService,
    private authService: SocialAuthService,
    private events: Events,
    public modalCtrl: ModalController
  ) {
    this.initSite();
    this.addSubscribeEvent();
  }

  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
 
  currentUrl = "/login";

  async initSite() {
    const head = document.head || document.getElementsByTagName('head')[0];
    const style: any = document.createElement('link');
    style.href = "https://fonts.googleapis.com/earlyaccess/notosanskr.css";
    style.rel = "stylesheet";

    setTimeout(() => {
      head.appendChild(style);
    }, 3000);
 

    this.app.user.initLanguage(); // 언어설정
    await this.app.user.fetchUser(); // 유저정보 갱신
  }


  // web일때 초기화 할것
  initWeb() {
    this.initSocialAuth(); // 앱에서 초기화할것
  }

  //////////////////////////////////////////////////////////////////////////
  // Web
  async initSocialAuth() {
    // 회원처리 여기서
    this.authService.authState.subscribe((result: SocialUser) => {
      if (result) {
     
        const user: SocialLogin = {
          provider: this.app.social.cache.provider,
          id: result.id,
          email: result.email,
          nickname: result.name,
          first_name: result.firstName,
          last_name: result.lastName,
          profile_img: result.photoUrl,
        };
        this.app.social.socialLoginProcess(user);
      }
    });
    // 회원처리 여기서 @
  }

  async addSubscribeEvent() {
    this.events.subscribe("home_enter", () => {
      // this.app.service.getMainUserList(); // 유저데이터만 갱신
    });
  }
}
