<ion-header>
  <ion-toolbar color="light">
    <ion-title>
      {{'ADULT_VERIFICATION' | translate }}</ion-title>
 

    <ion-buttons slot="end" >
      <ion-button (click)="close()">
        <ion-icon src="assets/icon/close-circle-outline.svg"></ion-icon>
      </ion-button>
    </ion-buttons>

  </ion-toolbar>
</ion-header>


<ion-content>
  <div class="modal-content-wrapper"> 

    <div class="message-box">
      {{'MGS_ADULT_VERIFICATION' | translate }}
    </div>

    <br>

    <div style="margin-top:10px;">    
      <ion-button expand="block" fill="solid" color="primary" (click)="agree()"> {{'BTN_ADULT_YES' | translate }}</ion-button>
      <br>
      <ion-button expand="block" color="light" (click)="close()"> {{'BTN_ADULT_NO'  | translate }}</ion-button>
    </div>

  </div>
  
</ion-content>
