<div class="inner-content modal-layer2 show-modal modal-buy-coin" id="modalBuyCoin">
  <div class="modal-inner">
      <div class="modal-content">
          <button class="btn-modal-close"(click)="close()"><img src="assets/img/btn_modal_close.svg" alt=""></button>
          <div class="modal-ttl">{{ 'BUY_E-CASH' | translate }}</div>
          <div class="payment-list-section">
            <div>
              <ul class="buy-coin-list">
                <li class="coin-item" *ngFor="let item of productList;let idx=index;" [ngClass]="{select:item.isSelect}"  (click)="selectPrice(idx, '0')">
                    <div>
                    <span class="coin-item-merch">{{item.en_title}}</span>  
                    <small class="coin-item-points">{{item.en_des}}</small>
                    <span class="coin-item-price">{{item.display_price}}</span>
                    </div>
                </li>
              </ul>
            </div>
            <div *ngIf="productFirstList.length !== 0">
              <span class="payment-first-title">{{ 'PRODUCT_FIRST' | translate }}</span>
              <ul class="buy-coin-list">
                <li class="coin-item-event" *ngFor="let item of productFirstList;let idxx=index;" [ngClass]="{select:item.isSelect}"  (click)="selectPrice(idxx, '2')">
                    <div class="coin-item-event-section">
                      <div>
                        <span class="coin-item-event-merch">{{item.en_title}}</span>
                      </div>
                      <div class="coin-item-event-items">
                        <img src="assets/img/icon_coin.svg" alt="">
                        <span class="coin-item-event-ecash">{{item.ecash}}</span>
                        <small class="coin-item-event-points">{{item.point}}</small>
                      </div>
                    </div>
                    <div class="coin-item-event-section-price">
                      <span class="coin-item-event-price">{{item.display_price}}</span>
                    </div>
                  </li>
              </ul>
            </div>
            <div *ngIf="productMonthList.length !== 0">
              <span class="payment-first-title">{{ 'PRODUCT_MONTHLY' | translate }}</span>
              <ul class="buy-coin-list">
                <li class="coin-item-event" *ngFor="let item of productMonthList;let idxx=index;" [ngClass]="{select:item.isSelect}"  (click)="selectPrice(idxx, '3')">
                  <div class="coin-item-event-section">
                    <div>
                      <span class="coin-item-event-merch">{{item.en_title}}</span>
                    </div>
                    <div class="coin-item-event-items">
                      <img src="assets/img/icon_coin.svg" alt="">
                      <span class="coin-item-event-ecash">{{item.ecash}}</span>
                      <small class="coin-item-event-points">{{item.point}}</small>
                    </div>
                  </div>
                  <div class="coin-item-event-section-price">
                    <span class="coin-item-event-price">{{item.display_price}}</span>
                  </div>
                  </li>
              </ul>
            </div>
          </div>
          
     
          <div class="coin-payment" *ngIf="!app.env.isCordova">
              <span>{{'SELECT_PAYMENT' | translate}}</span>
              <!-- 결제수단 선택 -->
              <ul>
                  <li (click)="selectPayment(PayMethod.paypal)">
                      <input type="radio" name="payment" id="pay1" checked>
                      <label for="pay1"></label>
                  </li>
                  <li (click)="selectPayment(PayMethod.stripe)">
                      <input type="radio" name="payment" id="pay2">
                      <label for="pay2"></label>
                  </li>
              </ul>
          </div>

          <!-- 결제하기버튼 -->
          <!-- <button class="btn_direct" (click)="doDirect()">TEST충전</button> -->
          <button class="buy" *ngIf="!app.env.isCordova && payData.pay_method == PayMethod.stripe" (click)="doStripe()">{{ 'Buy Coins' | translate }}</button>
          <div style="margin-top:10px;" #paypalRef [ngClass]="(!app.env.isCordova && payData.pay_method == PayMethod.paypal)?'':'hide'"></div>
          <!--결제버튼@-->
      </div>
  </div>                         
</div>
